/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-expressions */
import FormField from '@ingka/form-field';
import Text from '@ingka/text';
import './UnitDetails.css';
import React, { useEffect, useState } from 'react';
import InputField from '@ingka/input-field';
import {
  ConfigProvider, Select, Spin, Upload, UploadFile, UploadProps,
} from 'antd';
import IcDelete from '@ingka/ssr-icon/paths/trash-can';
import IcImage from '@ingka/ssr-icon/paths/image-stack';
import IcDownArrow from '@ingka/ssr-icon/paths/chevron-down-small';
import { LoadingOutlined } from '@ant-design/icons';
import ListIcon from '@ingka/ssr-icon/paths/list';
import TextArea from '@ingka/text-area';
import SSRIcon from '@ingka/ssr-icon';
import _ from 'lodash';
import { useLocation } from 'react-router-dom';
import { useTranslate } from '../../../../common/services/translationService.js';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  createUnit, setCommentsValid, setImageChanged, setUnitNameValid, setUnitSubmitValid, setUrlValid,
} from '../../../../store/reducers/create-unit-reducer';
import useCallApi from '../../../../common/services/apiService.js';
import { HFB } from '../../createProject/items/Items';
import RenderEmpty from '../../../../common/components/renderEmpty/RenderEmpty';
import { isNullEmptyOrUndefined, isValidUrl } from '../../../../common/services/commonService.js';
import { checkInvalidCharRegex, checkValidCharRegex, uploadFileTypes } from '../../../../common/constants/constant';
import { setLoader, setToast } from '../../../../store/reducers/create-proj-reducer';
import { offlineData } from '../../../../common/configs/offlineData';

const antIcon = (
  <LoadingOutlined
    style={{
      fontSize: 24,
      color: '#0058a3',
    }}
    spin
  />
);

const { Dragger } = Upload;

interface SelectorComponentProps {
  label: string;
  id: string;
  styleClass: string;
  options: HFB[] | Dropdown[];
  placeholder: string;
  value: string | undefined;
  defaultValue: string | undefined;
  disabled: boolean;
  onSelect: (val: any, option: any) => void
}

const SelectorComponent = ({
  label, id, styleClass, options, placeholder,
  value, defaultValue, disabled, onSelect,
}:
  SelectorComponentProps) => (
    <div className="udSelectWrapper">
      <Text className="udLabel">{label}</Text>
      <ConfigProvider renderEmpty={() => (
        <RenderEmpty
          empText="No data found"
          icon={ListIcon}
          smallType
        />
      )}
      >
        <Select
          id={id}
          showSearch
          getPopupContainer={(trigger) => trigger.parentNode}
          className={`${styleClass} ${disabled ? 'udDisabledSelector' : ''}`}
          options={options}
          placeholder={placeholder}
          fieldNames={{
            label: 'codeName',
            value: 'id',
          }}
          autoClearSearchValue={false}
          defaultOpen={false}
          defaultActiveFirstOption={false}
          value={value?.length !== 0 ? value : undefined}
          defaultValue={defaultValue}
          onSelect={onSelect}
          disabled={disabled}
          onFocus={() => null}
          onSearch={() => null}
          filterOption={
          (inputValue, option) => option?.codeName?.toUpperCase().indexOf(
            inputValue.toUpperCase(),
          ) !== -1
        }
          dropdownRender={(menu) => (menu)}
          data-testid={id}
          suffixIcon={<SSRIcon paths={IcDownArrow} />}
        />
      </ConfigProvider>
    </div>
);

export interface Dropdown {
  id: string;
  codeName: string;
}

export interface KeyVal {
  key: string;
  value: string;
}

const UnitDetails = () => {
  const E_UNIT_NAME_CHAR_LIMIT = useTranslate('customUnit.details.nameCharLimit');
  const E_UNIT_NAME_REQUIRED = useTranslate('tab.details.name_required');
  const E_UNIT_NAME_UNIQUE = useTranslate('customUnit.details.nameUnique');
  const E_UNIT_URL_REQUIRED = useTranslate('customUnit.details.urlRequired');
  const E_UNIT_URL_INVALID = useTranslate('common.details.invalidUrl');
  const location: any = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const { get } = useCallApi();
  const [HFBdata, setHFBdata] = useState<HFB[]>([]);
  const [PRAdata, setPRAdata] = useState<HFB[]>([]);
  const [PAdata, setPAdata] = useState<HFB[]>([]);
  const [statusData, setStatusData] = useState<Dropdown[]>([]);
  const [rocData, setRocData] = useState<Dropdown[]>([]);
  const [priceClassData, setPriceClassData] = useState<Dropdown[]>([]);
  const [messageErr, setMessageErr] = useState(false);
  const [deletedImages, setDeletedImages] = useState<any[]>([]);

  const fileUploadErr = useTranslate('fileUpload.format.error');
  const hfbPlaceholder = useTranslate('customUnit.details.hfbPlaceholder');
  const praPlaceholder = useTranslate('customUnit.details.praPlaceholder');
  const paPlaceholder = useTranslate('customUnit.details.paPlaceholder');
  const statusPlaceholder = useTranslate('customUnit.details.statusPlaceholder');
  const rocPlaceholder = useTranslate('customUnit.details.rocPlaceholder');
  const priceClassPlaceholder = useTranslate('customUnit.details.priceClassPlaceholder');
  const photosLabel = useTranslate('customUnit.details.photosLabel');
  const imgUploadHelper = useTranslate('common.imgUploadHelper');

  const [unitName, setUnitName] = useState<string>('');
  const [selectedHFB, setSelectedHFB] = useState<string>('');
  const [selectedPRA, setSelectedPRA] = useState<string>('');
  const [selectedPA, setSelectedPA] = useState<string>('');
  const [selectedState, setSelectedState] = useState<KeyVal>();
  const [selectedRoc, setSelectedRoc] = useState<KeyVal>();
  const [selectedPriceClass, setSelectedPriceClass] = useState<KeyVal>();
  const [piaFactsLink, setPiaFactsLink] = useState<string>('');
  const [comments, setComments] = useState<string>('');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const { post } = useCallApi();
  const editPrjtData = location.state?.unitData;
  const editPrjtDetails = editPrjtData?.detailsTab;
  const editPrjtItems = editPrjtData?.itemsTab;
  const isEditUnit = location.state?.isEdit || false;
  const isCopyUnit = location.state?.isCopy || false;

  const unitNameValid = useAppSelector((state) => state.customUnitReducer.unitNameValid);
  const customUnitData = useAppSelector((state) => state?.customUnitReducer?.createUnitData);
  const urlValid = useAppSelector((state) => state?.customUnitReducer?.urlValid);
  const [imageToken, setImageToken] = useState<any>();

  const dispatch = useAppDispatch();

  const getSasToken = () => {
    get('get-sas', setImageToken);
  };

  const getHFB = async () => {
    // Fetch enumData from IndexedDB using offlineData
    offlineData.getItem('enumData').then((enumData: any) => {
      const hfbEntry = enumData?.find((item: any) => item.key === 'CUS_HFB_LIST');

      if (hfbEntry?.value) {
        const hfbIdsArray = hfbEntry.value.split('|');

        // Set filtered HFB data based on CUS_HFB_LIST
        get('business-structure', (businessStructureData: HFB[]) => {
          const filteredData = businessStructureData.filter((hfb) => hfbIdsArray.includes(hfb.id));
          setHFBdata(filteredData);
        });
      } else {
        // If no CUS_HFB_LIST found or the value is empty, fetch the full HFB data
        get('business-structure', setHFBdata);
      }
    });
  };

  const getStatus = async () => {
    offlineData?.getItem('enumData').then(async (enumData: any) => {
      const statusEntry = enumData?.find((item: any) => item.key === 'ItemState')?.value;
      const statusArray = statusEntry?.split('|');
      const buildStatusData : Dropdown[] = [];
      statusArray?.forEach((st: any) => {
        const keyVal = st?.split(':');
        buildStatusData.push({
          codeName: keyVal[1],
          id: keyVal[1],
        });
      });
      setStatusData(buildStatusData);
    });
  };

  const getROC = async () => {
    get('rocs', (data: any) => {
      const rocList: Dropdown[] = [];
      data?.forEach((roc: any) => {
        rocList?.push({
          codeName: roc?.rocName,
          id: roc?.rocNo,
        });
      });
      setRocData(rocList);
    });
  };

  const getPriceClass = () => {
    get('price-level', (data: any) => {
      const priceClassList: Dropdown[] = [];
      data?.priceClass?.forEach((roc: any) => {
        priceClassList?.push({
          codeName: roc?.pcName,
          id: roc?.pcNo,
        });
      });
      setPriceClassData(priceClassList);
    });
  };

  const checkUniqueName = (value: any) => {
    if (value.trim().length === 0) {
      dispatch(setUnitNameValid({ status: false, message: E_UNIT_NAME_REQUIRED }));
      dispatch(setUnitSubmitValid(false));
    } else {
      const payload = {
        customUnitName: value?.trim(),
      };
      setIsLoading(true);
      post('validate-unit-name', payload, (data: any) => {
        if (data.isUnitNameExist === true) {
          dispatch(
            setUnitNameValid({
              status: !data.isUnitNameExist,
              message: E_UNIT_NAME_UNIQUE,
            }),
          );
          dispatch(setUnitSubmitValid(false));
        } else {
          dispatch(setUnitNameValid({ status: true, message: '' }));
          setUnitName(value);
        }
        setIsLoading(false);
      }, () => {
        dispatch(setUnitSubmitValid(false));
        dispatch(setUnitNameValid({ status: false, message: '' }));
        setIsLoading(false);
      });
    }
  };

  useEffect(() => {
    (isEditUnit === true || isCopyUnit === true) && getSasToken();
    isCopyUnit === true && checkUniqueName(editPrjtDetails?.Name);
    const fetchData = async () => {
      dispatch(setLoader(true));
      try {
        await Promise.all([getHFB(), getStatus(), getROC(), getPriceClass()]);
      } catch (error) {
        // handle error
      } finally {
        dispatch(setLoader(false));
      }
    };
    fetchData();
  }, []);

  const setDetailsVaLInStore = (val:any, key:string) => {
    const detailTabVal = { ...customUnitData.detailsTab };
    detailTabVal[key] = val;
    dispatch(createUnit({ ...customUnitData, detailsTab: detailTabVal }));
  };

  const setImgDetailsVaLInStore = (val:UploadFile<any>[]) => {
    const detailTabVal = _.cloneDeep(customUnitData.detailsTab);
    if (isEditUnit === true || isCopyUnit === true) {
      const imageAllData:any = [];
      val?.forEach((valImg:any, index:number) => {
        if (isNullEmptyOrUndefined(valImg?.lastModified)) {
          imageAllData.push(valImg);
        } else {
          offlineData.setItem(`unitImg${index}`, valImg);
          imageAllData.push(`unitImg${index}`);
        }
      });
      detailTabVal.Images = [...imageAllData];
    } else {
      const clonedVal = _.cloneDeep(val);
      const saveImgList: string[] = [];
      clonedVal.forEach((file: UploadFile<any>, index: number) => {
        offlineData.setItem(`unitImg${index}`, file);
        saveImgList.push(`unitImg${index}`);
      });
      detailTabVal.Images = [...saveImgList];
    }
    dispatch(createUnit({ ...customUnitData, detailsTab: detailTabVal }));
  };

  useEffect(() => {
    if (isEditUnit === true || isCopyUnit === true) {
      setPRAdata(HFBdata.find((val) => val.id === editPrjtDetails?.Hfb)?.items || []);
      setPAdata(PRAdata.find((val) => val.id === editPrjtDetails?.Pra)?.items || []);
      setSelectedHFB(editPrjtDetails?.Hfb || '');
      setSelectedPRA(editPrjtDetails?.Pra || '');
      setSelectedPA(editPrjtDetails?.Pa || '');
      const stateData = { key: editPrjtDetails?.unitState, value: editPrjtDetails?.unitState };
      setSelectedState(stateData || '');
      setSelectedRoc(editPrjtDetails?.roc || '');
      setSelectedPriceClass(editPrjtDetails?.priceClass || '');
      setUnitName(editPrjtDetails?.Name);
      setComments(editPrjtDetails?.Comments);
      setPiaFactsLink(editPrjtDetails?.PiaLink);
      if (!isNullEmptyOrUndefined(imageToken)) {
        const imgUrlList: UploadFile[] = [];
        editPrjtDetails?.Images?.forEach((image: any, index: string) => {
          const imageURL = image.concat(imageToken?.sasToken);
          const uploadFile: UploadFile = {
            fileName: editPrjtDetails?.ImageNames[index],
            name: editPrjtDetails?.ImageNames[index],
            uid: `${image.comparisonId}-${index}`,
            url: imageURL,
          };
          imgUrlList.push(uploadFile);
        });
        setFileList(imgUrlList);

        const detailTabVal = {
          Name: editPrjtDetails?.Name,
          Hfb: editPrjtDetails?.Hfb || '',
          Pra: editPrjtDetails?.Pra || '',
          Pa: editPrjtDetails?.Pa || '',
          PiaLink: editPrjtDetails?.PiaLink || '',
          Comments: editPrjtDetails?.Comments || '',
          unitState: stateData || '',
          roc: editPrjtDetails?.roc || '',
          priceClass: editPrjtDetails?.priceClass || '',
          Images: imgUrlList,
        };
        dispatch(createUnit({
          ...customUnitData,
          detailsTab: detailTabVal,
          itemsTab: { importedItems: editPrjtItems?.importedItems || [] },
        }));
      }
    }
  }, [editPrjtData, HFBdata, PRAdata, imageToken]);

  useEffect(() => {
    isNullEmptyOrUndefined(selectedPA) && setDetailsVaLInStore('', 'Pa');
  }, [selectedPA]);

  const onHfbSelect = (id: string) => {
    if (selectedHFB !== id) {
      setSelectedHFB(id);
      setSelectedPRA('');
      setSelectedPA('');
      setPRAdata(HFBdata.find((val) => val.id === id)?.items || []);
      setDetailsVaLInStore(id, 'Hfb');
    }
  };

  const onPraSelect = (id: string) => {
    if (selectedPRA !== id) {
      setSelectedPRA(id);
      setSelectedPA('');
      setPAdata(PRAdata.find((val) => val.id === id)?.items || []);
      setDetailsVaLInStore(id, 'Pra');
    }
  };

  const onNameChange = (e: any) => {
    const nameVal = e.target.value;
    if (nameVal.length <= 150) {
      setUnitName(nameVal);
      setDetailsVaLInStore(nameVal, 'Name');
    }
    if (nameVal.length > 150) {
      dispatch(setUnitNameValid({ status: false, message: E_UNIT_NAME_CHAR_LIMIT }));
    } else if (nameVal.length === 0 || (nameVal.trim().length === 0 && nameVal.length > 0)) {
      dispatch(setUnitNameValid({ status: false, message: E_UNIT_NAME_REQUIRED }));
    } else {
      dispatch(setUnitNameValid({ status: false, message: '' }));
    }
  };

  const onPiaFactsLinkChange = (e: any) => {
    const urlVal = e.target.value;
    setPiaFactsLink(urlVal);
    setDetailsVaLInStore(urlVal, 'PiaLink');
    if (urlVal.trim() === '') {
      dispatch(setUrlValid({ status: false, message: E_UNIT_URL_REQUIRED }));
    } else if (!isValidUrl(urlVal)) {
      dispatch(setUrlValid({ status: false, message: E_UNIT_URL_INVALID }));
    } else if (isValidUrl(urlVal)) {
      dispatch(setUrlValid({ status: true, message: '' }));
    }
  };

  const onCommentsChange = (e: any) => {
    const messageVal = e.target.value;
    if (!isNullEmptyOrUndefined(messageVal)) {
      if (checkInvalidCharRegex.test(messageVal) === false
      && checkValidCharRegex.test(messageVal) === true) {
        setMessageErr(false);
        dispatch(setCommentsValid(true));
      } else {
        setMessageErr(true);
        dispatch(setCommentsValid(false));
      }
    } else setMessageErr(false);
    const messageData = messageVal.length <= 600 ? messageVal : messageVal.slice(0, 600);
    setComments(messageData);
    setDetailsVaLInStore(messageData, 'Comments');
  };

  const handleChange: UploadProps['onChange'] = ({
    fileList: newFileList,
  }) => {
    dispatch(setImageChanged(true));
    const fList = newFileList.filter((val:any) => uploadFileTypes.includes(val.type) || val.url);
    setFileList(fList);
    setImgDetailsVaLInStore(fList);
  };

  const uploadProps: UploadProps = {
    name: 'IMAGE',
    listType: 'picture',
    showUploadList: {
      removeIcon: <SSRIcon className="udIcDelete" paths={IcDelete} />,
      showPreviewIcon: false,
    },
    className: 'upload-list-inline',
    multiple: true,
    accept: 'image/*',
    onPreview: () => null,
    onRemove: (file) => {
      dispatch(setImageChanged(true));
      const isOriginFile = isNullEmptyOrUndefined(file?.originFileObj);
      if (isOriginFile === true && isEditUnit === false) {
        setDeletedImages([...deletedImages, file]);
      } else {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);
        setFileList(newFileList);
        setImgDetailsVaLInStore(newFileList);
      }
    },
    beforeUpload: (file) => {
      const isFileSupported = uploadFileTypes.includes(file.type);
      if (!isFileSupported) {
        dispatch(setToast({ openToast: true, toastLabel: fileUploadErr }));
      } else {
        setFileList((fileLists) => [...fileLists, file]);
      }
      return false;
    },
    fileList,
    onChange: handleChange,
  };

  return (
    <div className="udParent">
      <Text
        className="tabTitle"
        headingSize="m"
        tagName="h1"
      >
        {useTranslate('customUnit.details.newUnit')}
      </Text>
      <div className="udContentWrapper">
        <div className="udLeftWrapper">
          <SelectorComponent
            label={useTranslate('customUnit.details.hfbLabel')}
            id="udHfbSelector"
            styleClass="udSelector"
            options={HFBdata}
            placeholder={hfbPlaceholder}
            value={selectedHFB}
            defaultValue={undefined}
            disabled={false}
            onSelect={(val: any, option: any) => onHfbSelect(option.id)}
          />
          <SelectorComponent
            label={useTranslate('customUnit.details.praLabel')}
            id="udPraSelector"
            styleClass="udSelector"
            options={PRAdata}
            placeholder={praPlaceholder}
            value={selectedPRA}
            defaultValue={undefined}
            disabled={isNullEmptyOrUndefined(selectedHFB)}
            onSelect={(val: any, option: any) => onPraSelect(option.id)}
          />
          <SelectorComponent
            label={useTranslate('customUnit.details.paLabel')}
            id="udPaSelector"
            styleClass="udSelector"
            options={PAdata}
            placeholder={paPlaceholder}
            value={selectedPA}
            defaultValue={undefined}
            disabled={isNullEmptyOrUndefined(selectedHFB) || isNullEmptyOrUndefined(selectedPRA)}
            onSelect={(val: any, option: any) => {
              setSelectedPA(option.id); setDetailsVaLInStore(option.id, 'Pa');
            }}
          />
          <SelectorComponent
            label={useTranslate('customUnit.details.statusLabel')}
            id="udStatusSelector"
            styleClass="udSelector"
            options={statusData}
            placeholder={statusPlaceholder}
            value={selectedState?.value}
            defaultValue={undefined}
            disabled={false}
            onSelect={(val: any, option: any) => {
              setSelectedState(
                { key: option.codeName, value: option.id },
              );
              setDetailsVaLInStore({ key: option.codeName, value: option.id }, 'unitState');
            }}
          />
          <SelectorComponent
            label={useTranslate('customUnit.details.rocLabel')}
            id="udRocSelector"
            styleClass="udSelector"
            options={rocData}
            placeholder={rocPlaceholder}
            value={selectedRoc?.value}
            defaultValue={undefined}
            disabled={false}
            onSelect={
              (val: any, option: any) => {
                setSelectedRoc({ key: option.codeName, value: option.id });
                setDetailsVaLInStore({ key: option.codeName, value: option.id }, 'roc');
              }
            }
          />
          <SelectorComponent
            label={useTranslate('customUnit.details.priceClassLabel')}
            id="udPriceClassSelector"
            styleClass="udSelector"
            options={priceClassData}
            placeholder={priceClassPlaceholder}
            value={selectedPriceClass?.value}
            defaultValue={undefined}
            disabled={false}
            onSelect={(val: any, option: any) => {
              setSelectedPriceClass(
                { key: option.codeName, value: option.id },
              );
              setDetailsVaLInStore({ key: option.codeName, value: option.id }, 'priceClass');
            }}
          />
        </div>
        <div className="udRightWrapper">
          <FormField
            className="udFormField"
            shouldValidate={!unitNameValid.status && unitNameValid.message.length > 0}
            validation={{
              id: 'error-msg-id',
              msg: unitNameValid.message,
              type: 'error',
            }}
          >
            <InputField
              id="unitName"
              className="udTextField"
              label={useTranslate('customUnit.details.nameLabel')}
              type="text"
              value={unitName?.length !== 0 ? unitName : undefined}
              autoComplete="off"
              disabled={isEditUnit === true}
              suffixLabel={isLoading ? (
                <Spin
                  indicator={antIcon}
                  style={{ marginRight: '5px' }}
                />
              ) : undefined}
              onChange={(e) => onNameChange(e)}
              onBlur={(e: any) => checkUniqueName(e.target.value)}
            />
          </FormField>
          <FormField
            className="udFormField"
            shouldValidate={!isNullEmptyOrUndefined(comments) && messageErr === true}
            characterLimit={600}
            validation={{
              id: 'error-msg-comment',
              msg: useTranslate('error.textAreaInvalid'),
              type: 'error',
            }}
          >
            <TextArea
              id="udComments"
              label={useTranslate('customUnit.details.commentsLabel')}
              value={comments?.length !== 0 ? comments : undefined}
              onChange={onCommentsChange}
            />
          </FormField>
          <FormField
            className="udFormField"
            shouldValidate={!urlValid.status && urlValid.message.length > 0}
            validation={{
              id: 'error-msg-url',
              msg: urlValid.message,
              type: 'error',
            }}
          >
            <InputField
              id="piaFactsLink"
              className="udTextField"
              label={useTranslate('customUnit.details.PiaFactsLabel')}
              type="text"
              value={piaFactsLink?.length !== 0 ? piaFactsLink : undefined}
              autoComplete="off"
              onChange={onPiaFactsLinkChange}
              onBlur={onPiaFactsLinkChange}
            />
          </FormField>
          <div className="udImgUploadWrapper">
            <Text className="udLabel">{photosLabel}</Text>
            <div className="udImgUploadContainer">
              <Dragger {...uploadProps}>
                <p className="udImgUploadIcon">
                  <SSRIcon className="icPhoto" paths={IcImage} />
                </p>
                <div className="udImgUploadHelperWrapper">
                  <p className="udImgUploadHelper">{imgUploadHelper}</p>
                  {/* <p className="udImgUploadHelperTwo">{imgUploadHelperTwo}</p> */}
                </div>
              </Dragger>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnitDetails;
