/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import Modal, { ModalBody, ModalHeader, Theatre } from '@ingka/modal';
import './AddCompetitorPopup.css';
import { Checkbox, Select } from 'antd';
import Text from '@ingka/text';
import Button from '@ingka/button';
import { useTranslate } from '../../../../common/services/translationService.js';
import { isNullEmptyOrUndefined, userType } from '../../../../common/services/commonService.js';
import useCallApi from '../../../../common/services/apiService.js';

interface AddCompetitorPopupProps {
  open: boolean;
  onClose: () => void;
}

const AddCompetitorPopup = ({
  open, onClose,
}: AddCompetitorPopupProps) => {
  const [competitorList, setCompetitorsList] = useState<any>([]);
  const [selectedCompetitorId, setSelectedCompetitorId] = useState<number | string>();
  const [searchCompValue, setSearchCompValue] = useState('');
  const [selectedCompetitor, setSelectedCompetitor] = useState<string | number>();
  const [addNewCompFlag, setAddNewCompFlag] = useState<boolean>(false);
  const { post } = useCallApi();
  const txtAddCompetitor = useTranslate('compAdmin.addComp.title');

  const getCompetitorsList = (val: any) => {
    const compPayload = {
      UnitCode: val,
      ProjectType: userType(),
    };
    post('competitors', compPayload, (res:any) => setCompetitorsList(res?.competitorInfoViewDto || []));
  };

  useEffect(() => {
    getCompetitorsList('GB');
  }, []);

  const setCompetitor = (value: string | number, name: string | number, type: string) => {
    if (type === 'id') {
      setSelectedCompetitorId(value);
      setSelectedCompetitor(name);
    } else {
      setSelectedCompetitor(value);
      setSelectedCompetitorId('');
    }
  };

  const setAddNewComp = () => {
    setCompetitorsList([
      ...competitorList,
      { competitorName: searchCompValue, competitorId: searchCompValue },
    ]);
    setAddNewCompFlag(!addNewCompFlag);
    setCompetitor(searchCompValue, searchCompValue, 'id');
  };

  const addNewComp = (
    <div className="acpAddAsNew">
      <Checkbox
        id="addNewComp"
        name="addNewComp"
        onChange={setAddNewComp}
        data-testid="add-new-competitor-checkbox"
      >
        {`Add “${searchCompValue}” as a new competitor`}
      </Checkbox>
    </div>
  );

  const disableAddButton = isNullEmptyOrUndefined(selectedCompetitor);

  return (
    <Modal
      className="acpModal"
      handleCloseBtn={onClose}
      visible={open}
      escapable={false}
    >
      <Theatre
        aria-labelledby="importCsvModal"
        header={(
          <ModalHeader
            titleId="importCsvTitle"
            title={txtAddCompetitor}
            floating={false}
          />
      )}
      >
        <ModalBody className="acpModalBody">
          <div className="acpBodyInnerWrapper">
            <Text className="acpInputLabel">Enter competitor name</Text>
            <Select
              showSearch
              getPopupContainer={(trigger) => trigger.parentNode}
              className="acpDropdown"
              options={competitorList || []}
              fieldNames={{
                label: 'competitorName',
                value: 'competitorName',
              }}
              autoClearSearchValue={false}
              defaultOpen={false}
              popupClassName="competitorDropdown"
              defaultActiveFirstOption={false}
              value={selectedCompetitor as any}
              defaultValue={selectedCompetitor as any}
              disabled={false}
              onSelect={(val: any, option: any) => setCompetitor(option.competitorId, option.competitorName, 'id')}
              onSearch={(val: any) => {
                setSearchCompValue(val);
                val && setAddNewCompFlag(false);
              }}
              filterOption={(inputValue, option) => option?.competitorName?.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
              dropdownRender={(menu) => (menu)}
              notFoundContent={addNewComp}
              data-testid="competitor-select"
              suffixIcon={null}
            />
            <Button
              className="acpAddButton"
              text={useTranslate('compAdmin.addComp.title')}
              type="emphasised"
              disabled={disableAddButton}
              onClick={() => null}
            />
          </div>
        </ModalBody>
      </Theatre>
    </Modal>
  );
};

export default AddCompetitorPopup;
