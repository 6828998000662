import React, { useState } from 'react';
import '@ingka/switch/dist/style.css';
import '@ingka/forms/dist/style.css';
import '@ingka/focus/dist/style.css';
import './CompetitorAdmin.css';
import CompetitorAdminFilter from './filters/CompetitorAdminFilter';
import AddCompetitorPopup from './addCompetitorPopup/AddCompetitorPopup';

const CompetitorAdmin = () => {
  const [openAddPopup, setOpenAddPopup] = useState(false);

  return (
    <div className="competitorAdminContainer">
      <CompetitorAdminFilter onAddClick={() => setOpenAddPopup(true)} />
      <AddCompetitorPopup open={openAddPopup} onClose={() => setOpenAddPopup(false)} />
    </div>
  );
};

export default CompetitorAdmin;
