import React from 'react';
import '@ingka/svg-icon/dist/style.css';
import '@ingka/pill/dist/style.css';
import '@ingka/focus/dist/style.css';
import '@ingka/loading/dist/style.css';
import Button from '@ingka/button';
import SearchData from '@ingka/search';
import { useTranslate } from '../../../../common/services/translationService.js';
import './CompetitorAdminFilter.css';
import PageHeader from '../../../../common/components/header/Header';

interface CompetitorAdminFilterProps {
  onAddClick: () => void;
}

const CompetitorAdminFilter = ({
  onAddClick,
}: CompetitorAdminFilterProps) => (
  <div>
    <PageHeader
      title={useTranslate('competitor.admin.overview')}
      isEnable={false}
      buttonLabel=""
    />
    <div className="caContentWrapper">
      <div className="caHeaderWrapper" id="caHeaderWrapperDiv">
        <div className="flexbox">
          <div className="searchBoxWrapper">
            <SearchData
              className="search_pill_ca"
              id="caSearch"
              placeholder={useTranslate('tab.admin.search_items')}
            />
          </div>
          <div className="addCompButton">
            <Button
              text={useTranslate('compAdmin.addComp.title')}
              type="emphasised"
              small
              onClick={onAddClick}
            />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default CompetitorAdminFilter;
